import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./CheckAllResponse.module.css";
import { useGoogleRecaptcha } from "google-recaptcha-v3";
import swal from "sweetalert";

interface CheckAllResponseProps {
  success: boolean;
  data: {
    [network: string]: string[];
  };
}

const CheckAllResponse: React.FC = () => {
  const [urls, setUrls] = useState<string>("");
  const [response, setResponse] = useState<CheckAllResponseProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseTime, setResponseTime] = useState<number | null>(null);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [error, setError] = useState<string>("");

  const SITE_KEY = "6Lf8htApAAAAANF-tTvJnz0rC-wx98rsV79UEnfm";
  const { token: recaptchaToken, fetchCaptcha } = useGoogleRecaptcha(
    SITE_KEY,
    "submit",
    {
      language: "vi",
    }
  );
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (loading) {
      setCountdown(10);
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown && prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            clearInterval(timer as NodeJS.Timeout);
            return null;
          }
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [loading]);

  const fetchData = async () => {
    fetchCaptcha();
    const urlArray = urls
      .split(/[\n,]/)
      .map((url) => url.trim())
      .filter((url) => url);

    if (urlArray.length === 0) {
      alert("Please enter at least one URL");
      return;
    }

    setLoading(true);
    setResponse(null);
    setResponseTime(null);
    setError("")
    const startTime = Date.now();

    try {
      const result = await axios.post(
        "https://api-checknetwork.f8bet.company/checkall",
        {
          urls: urlArray,
        },
        {
          headers: {
            "Content-Type": "application/json",
            recaptcha: recaptchaToken,
          },
        }
      );
      console.log(result?.data)

      const endTime = Date.now();
      setResponseTime(endTime - startTime);
      setResponse(result.data);
    } catch (error: any) {
      swal("Error", `${error?.response.data?.error}`, "error");

    } finally {
      setLoading(false);
      setCountdown(null);
    }
  };

  return (
    <div className={styles.container}>
      <textarea
        className={styles.textarea}
        value={urls}
        disabled={loading}
        readOnly={loading}
        onChange={(e) => setUrls(e.target.value)}
        placeholder="Enter URLs, one per line or separated by commas, Ex: https://google.com, https://facebook.com or google.com, facebook.com ,https://103.127.199.999"
      />
      <p style={{ color: "red", fontSize: "14px" }}>
        {loading && countdown !== null
          ? `Vui lòng chờ: 8 - 10s, nếu vượt quá 10s đồng nghĩa với việc trang web bị chặn bởi toàn bộ nhà mạng !`
          : ""}
      </p>
      <p style={{ color: "green", fontSize: "14px" }}>
        {loading && countdown !== null
          ? `Thời gian xử lý còn lại : ${countdown}`
          : ""}
      </p>
      <button disabled={loading} onClick={fetchData} className={styles.button}>
        {loading ? "Checking..." : "Check URL"}
      </button>
      {loading ? (
        <div className={styles.skeletonContainer}>
          <Skeleton count={5} />
        </div>
      ):response && response.success ? (
        <div className={styles.responseContainer}>
          <p>
            Response Time: {responseTime && (responseTime / 1000).toFixed(1)} s
          </p>
          {Object.entries(response.data).map(([network, messages]) => (
            <div key={network} className={styles.networkContainer}>
              <h3 className={styles.networkTitle}>{network}</h3>
              <ul className={styles.messageList}>
                {messages.map((message, index) => (
                  <li key={index} className={styles.messageItem}>
                    {message}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : (
        <p className={styles.errorMessage}>
          Thông tin kiểm tra sẽ được hiển thị tại đây
        </p>
      )}
    </div>
  );
};

export default CheckAllResponse;
